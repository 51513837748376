<template>
    <div class="Awarp">
        <dashCard class="dieselgeneratorBox6" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">负荷分布</template>
            <template slot="aside">
                <div class="type-group">
                    <span class="pick-type" v-for="(item,idx) in timetType" :key="idx" @click="changeType(item.code)">
                        <font :class="{'isactive-type': item.code == searchObj.TYPE}">{{ item.name }}</font>
                    </span>
                </div>
                <a-date-picker v-if="searchObj.TYPE=='按天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"/>
                <a-week-picker v-if="searchObj.TYPE=='按周'" :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"  v-model="searchObj.VALUE" @change="onChange" />
                <a-month-picker v-if="searchObj.TYPE=='按月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="searchObj.VALUE" @change="onChange" />
                <a-date-picker v-if="searchObj.TYPE=='按年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"
                mode="year"
                :open="panelOpen"
                @openChange="openChange"
                @panelChange="panelChange"
                format="YYYY"
                />
            </template>
            <div class="card-content1">
                <div class="content1-line">
                    <div>
                        <span>累计时间</span>
                        <span>7,233<font>h</font></span>
                    </div>
                </div>
            </div>

            <div class="card-content2">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <dieselgeneratorBox6  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'dieselgeneratorBox6',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                timetType: [
                {
                    code: '按天',
                    name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
                },
                {
                    code: '按周',
                    name:'按周'
                },
                {
                    code: '按月',
                    name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
                },
                {
                    code: '按年',
                    name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
                }
            ],
            panelOpen: false,
            searchObj: {
                TYPE: '按年',//按天,按周,按月,按年
                VALUE: this.$moment(),//日期
            },
            chartOption1: {},
            detailInfo: {
                chart1: {}
            },
            colorList: ['#0CA919','#FF7300',"#3366FF","#FFD800","#F03040"]
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },


        // 改变类型
        changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
        },
        // 切换项目
        handleChange(value) {
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                seriesData: [
                {
                    name: "0%~30%",
                    value: 5,
                },
                {
                    name: "30%~50%",
                    value: 5,
                },
                {
                    name: "50%~80%",
                    value: 30,
                },
                {
                    name: "80%~100%",
                    value: 15,
                },
                {
                    name: "100%~110%",
                    value: 3,
                },
                ]
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {
            var {seriesData} = this.detailInfo.chart1
            var colorList = this.colorList
            var option = {
                animation:false,
                layoutAnimation:false,
                legend: {
                    right: 0,
                    top: 'center',
                    orient: "vertical",
                    icon: "rect", //图例形状
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#1d2129'
                    },
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [{
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                return colorList[params.dataIndex]
                            },
                            borderColor: '#172659',
                            borderWidth: 0
                        }
                    },
                    type: 'pie',
                    radius: ['70%', '85%'],
                    center: ["50%", "50%"],
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: '#34569D'
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                            formatter: params => {
                                return (
                                    '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                                );
                            },
                            padding: [0, -5, 0, -5],
                        }
                    },
                    data: seriesData
                }
                ]
            }

            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },

        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.dieselgeneratorBox6 {
    .card-content1 {
        .content1-line {
            display: flex;
            >div {
                height: 68px;
                width: 185px;
                display: flex;
                flex-direction: column;
                >span {
                    &:nth-child(1) {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0,0,0,0.65);
                        line-height: 22px;
                    }
                    &:nth-child(2) {
                        font-size: 32px;
                        font-weight: 500;
                        color: #1d2129;
                        line-height: 40px;
                        font {
                            margin-left: 6px;
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(0,0,0,0.45);
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
    .card-content2 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
}
</style>
